export type DeferredPromise<T = unknown> = {
  promise: Promise<T>
  resolve: (value?: T) => void
  reject: (reason?: any) => void
}

export const deferredPromise = <T>(): DeferredPromise<T> => {
  const deferred: any = {}
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })
  return deferred
}
