<script setup lang="ts">
import '@adyen/adyen-web/dist/adyen.css'
import {
  getShippingMethods,
  isPickUpInStore,
  isShippingMethodFree,
} from '@design-system/utils/shippingMethods'
import {
  getAdyenPaymentMethodId,
  isAdyenPaymentMethod,
} from '@integration-layer/utils/paymentMethods'

const { ts } = useI18n()
const { language, thankYouUrl } = useRouteHelper()
const { formatPriceByLocale } = useFormatPrice()

const {
  cart,
  setPaymentMethod,
  setAdyenPaymentSource,
  updateAdyenPaymentSource,
  place,
  setShippingMethod,
  setShippingAddress,
  setBillingAddress,
  setCustomerEmail,
  removeAddresses,
} = useCart()

const { country } = useRouteHelper()
const { marketShippingMethods } = useShippingMethods()

let component: any

const isLoading = useLoaderState()
const { addSnackbar } = useHeaderSnackbars()
const setError = () => {
  isLoading.value = false
  addSnackbar(`adyen-error`, {
    message: {
      key: 'checkout.paymentStep.error',
    },
    duration: 0,
    textClasses: 'text-primitives-red',
  })
}

const getTransactionInfo = (): google.payments.api.TransactionInfo => ({
  displayItems: [
    {
      label: ts('checkoutPage.recapSidebar.subtotal'),
      type: 'SUBTOTAL',
      price: cart.value!.subtotal_amount_float!.toString(),
    },
    ...(cart.value!.tax_included
      ? []
      : [
          {
            label: ts('cartPage.recap.tax'),
            type: 'TAX' as const,
            status: cart.value!.shipping_address ? 'FINAL' : 'PENDING',
            price: cart.value!.total_tax_amount_float!.toString(),
          } as google.payments.api.DisplayItem,
        ]),
    {
      label: 'Shipping',
      type: 'SHIPPING_OPTION' as const,
      status:
        cart.value!.shipments?.length! > 0 &&
        cart.value!.shipments?.[0].shipping_method
          ? 'FINAL'
          : 'PENDING',
      price: cart.value!.shipping_amount_float!.toString(),
    },
    ...(cart.value!.discount_amount_float
      ? []
      : [
          {
            label: ts('cartPage.recap.promoCodeLabel'),
            type: 'DISCOUNT',
            price: cart.value!.discount_amount_float!.toString(),
          } as google.payments.api.DisplayItem,
        ]),
  ],
  countryCode: country.toUpperCase(),
  currencyCode: cart.value!.currency_code!,
  totalPriceStatus: 'ESTIMATED',
  totalPrice: cart.value!.total_amount_with_taxes_float!.toString(),
  totalPriceLabel: ts('cartPage.recap.total'),
})

const currentShippingOptions = computed<google.payments.api.SelectionOption[]>(
  () => {
    return cart.value?.shipments?.length
      ? getShippingMethods(cart.value!)
          .filter(method => !isPickUpInStore(method))
          .map(
            (method): google.payments.api.SelectionOption => ({
              id: method.id,
              label: isShippingMethodFree(cart.value!, method)
                ? ts('free')
                : formatPriceByLocale(
                    method.currency_code!,
                    method.price_amount_float!
                  ),
              description: method.name,
            })
          )
      : marketShippingMethods.value.map(
          (method): google.payments.api.SelectionOption => ({
            id: method.id,
            label: method.cost,
            description: method.type,
          })
        )
  }
)

const defaultShippingOption = computed(
  () => currentShippingOptions.value?.[0]?.id
)

const setupPayment = async () => {
  if (!cart.value) return

  const paymentMethod = cart.value.payment_method

  if (!paymentMethod || !isAdyenPaymentMethod(paymentMethod)) {
    const adyenPaymentMethodId = getAdyenPaymentMethodId(cart.value)
    if (!adyenPaymentMethodId) throw new Error('adyenPaymentMethodId not found')

    await setPaymentMethod(adyenPaymentMethodId)
    await setAdyenPaymentSource()
  }

  const paymentMethods =
    // @ts-ignore
    cart.value.payment_source?.payment_methods?.paymentMethods

  if (
    !paymentMethods.some(({ type }: { type: string }) => type === 'googlepay')
  ) {
    return
  }

  const { default: AdyenCheckout } = await import('@adyen/adyen-web')
  // @ts-ignore
  const checkout = await AdyenCheckout({
    // @ts-expect-error
    environment: cart.value?.payment_source?.public_key?.startsWith('test')
      ? 'test'
      : 'live',
    // @ts-expect-error
    clientKey: cart.value?.payment_source?.public_key,
    locale: language,
    // amount: {
    //   currency: cart.value?.currency_code || '',
    //   value: cart.value?.total_amount_with_taxes_cents || 0,
    // },
    countryCode: country.toUpperCase(),
    paymentMethodsResponse: {
      paymentMethods,
    },
    showPayButton: true,
    onError: handleError,
    onChange: handleOnChange,
    onAdditionalDetails: handleOnAdditionalDetails,
    onSubmit: onSubmit,
  })

  component = checkout.create('googlepay', {
    buttonSizeMode: 'fill',
    emailRequired: true,
    buttonColor: 'white',

    shippingAddressRequired: true,
    shippingAddressParameters: {
      allowedCountryCodes: [country.toUpperCase()],
      phoneNumberRequired: true,
    },

    shippingOptionRequired: true,
    shippingOptionParameters: {
      defaultSelectedOptionId: defaultShippingOption.value,
      shippingOptions: currentShippingOptions.value,
    },

    transactionInfo: getTransactionInfo(),

    callbackIntents: ['SHIPPING_ADDRESS', 'SHIPPING_OPTION'],

    paymentDataCallbacks: {
      async onPaymentDataChanged(intermediatePaymentData) {
        const { callbackTrigger, shippingOptionData, shippingAddress } =
          intermediatePaymentData
        const paymentDataRequestUpdate: any = {}

        if (
          shippingAddress &&
          (callbackTrigger === 'SHIPPING_ADDRESS' ||
            callbackTrigger === 'INITIALIZE')
        ) {
          await setShippingAddress({
            first_name: 'placeholder',
            last_name: 'placeholder',
            line_1: 'placeholder',
            city: shippingAddress.locality,
            zip_code: shippingAddress.postalCode,
            state_code: shippingAddress.administrativeArea,
            country_code: shippingAddress.countryCode,
            phone: 'placeholder',
          })
        }

        if (
          shippingOptionData &&
          (callbackTrigger === 'SHIPPING_OPTION' ||
            callbackTrigger === 'INITIALIZE')
        ) {
          await setShippingMethod(shippingOptionData.id)
        }

        paymentDataRequestUpdate.newTransactionInfo = getTransactionInfo()

        return paymentDataRequestUpdate
      },
    },
    onAuthorized: async paymentData => {
      try {
        // emailRequired is set to true
        await setCustomerEmail(paymentData.email!)
        // shippingAddressRequired is set to true
        const shippingAddress = paymentData.shippingAddress!
        const [firstName, ...lastNameParts] =
          shippingAddress.name?.split(' ') ?? []

        const currentAddress = {
          first_name: firstName,
          last_name: lastNameParts.join(' '),
          line_1: shippingAddress.address1!,
          city: shippingAddress.locality,
          state_code: shippingAddress.administrativeArea,
          zip_code: shippingAddress.postalCode,
          country_code: shippingAddress.countryCode,
          // phoneNumberRequired is set to true
          phone: shippingAddress.phoneNumber!,
        }
        await setShippingAddress(currentAddress)
        await setBillingAddress(currentAddress)
        // shippingOptionRequired is set to true
        await setShippingMethod(paymentData.shippingOptionData!.id)
        await handlePayment(onSubmitState, onSubmitComponent)
        authorizeDeferredPromise?.resolve()
      } catch (err) {
        authorizeDeferredPromise?.reject(err)
      }
    },
  })

  const isAvailable = await component.isAvailable()
  if (isAvailable) {
    component.mount(`#googlepay-container`)
  }

  setTimeout(() => {
    const node = component._node as HTMLElement
    node.querySelector('svg')?.setAttribute('aria-hidden', 'true')
    node.querySelector('iframe')?.setAttribute('aria-label', 'Google Payment')

    const button = node.querySelector('button')
    if (button) {
      button.style.borderRadius = '0'
    }
  }, 100)
}

function cleanUrlBy(symbol: string = '?'): string {
  const currentLocation = window?.location.href
  const [splitLocation] = currentLocation.split(symbol)
  const location = splitLocation ?? currentLocation
  const locationWithoutTrilingSlash = location.endsWith('/')
    ? location.slice(0, -1)
    : location
  return locationWithoutTrilingSlash
}

const getPaymentRequestData = (state: any) => ({
  payment_method: state.data.paymentMethod,
  origin: window.location.origin,
  return_url: `${cleanUrlBy()}/adyen`,
  redirect_from_issuer_method: 'GET',
  browser_info: {
    acceptHeader:
      'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
    ...getBrowserInfo(),
  },
})

const handleOnChange = async (state: any, component: any) => {
  if (state.isValid) {
    await updateAdyenPaymentSource({
      payment_request_data: {
        shopperInteraction: 'Ecommerce',
        recurringProcessingModel: 'CardOnFile',
        ...getPaymentRequestData(state),
      },
    })
  }
}

const handleOnAdditionalDetails = async (state: any, component: any) => {
  const paymentSource = await updateAdyenPaymentSource({
    payment_request_details: state.data,
    _details: true,
  })

  handlePaymentResponse(paymentSource.payment_response, component)
}

const handlePayment = async (state: any, component: any) => {
  const paymentRequestData = {
    ...state.data,
    ...getPaymentRequestData(state),
  }

  await updateAdyenPaymentSource({
    payment_request_data: paymentRequestData,
  })

  // delete paymentRequestData.paymentMethod
  const paymentSource = await updateAdyenPaymentSource({
    // payment_request_data: paymentRequestData,
    // @ts-expect-error
    _authorize: true,
  })

  await handlePaymentResponse(paymentSource.payment_response, component)
}

const handlePaymentResponse = async (paymentResponse: any, component: any) => {
  if (paymentResponse.action !== undefined) {
    component.handleAction(paymentResponse.action)
  } else {
    switch (paymentResponse.resultCode) {
      case 'Authorised':
      case 'Pending':
      case 'Received': {
        const order = await place()
        await navigateTo(thankYouUrl(order!.id))
        return
      }
      case 'Cancelled':
      case 'Error':
      case 'Refused': {
        setError()
        break
      }
    }
    if (paymentResponse.errorCode) {
      setError()
    }
  }
}

const handleError = (error: any) => {
  console.log(error)
  removeAddresses().catch(() => null)
  setError()
  authorizeDeferredPromise?.reject()
}

let onSubmitState: any
let onSubmitComponent: any
let authorizeDeferredPromise: DeferredPromise | undefined

const onSubmit = async (state: any, component: any) => {
  try {
    isLoading.value = true
    onSubmitState = state
    onSubmitComponent = component
    authorizeDeferredPromise = deferredPromise()
    await authorizeDeferredPromise.promise
  } catch (err) {
    isLoading.value = false
    console.log('onSubmit error', err)
    throw err
  }
}

onMounted(setupPayment)
</script>

<template>
  <div id="googlepay-container" ref="gpayContainer" class="w-full"></div>
</template>

<style scoped lang="scss">
.adyen-checkout__paywithgoogle {
  width: 100%;
}
</style>
