import type { Order, PaymentMethod } from '@commercelayer/sdk'

export const isAdyenPaymentMethod = (
  p: PaymentMethod
): p is PaymentMethod & { payment_source_type: 'adyen_payments' } =>
  p.payment_source_type === 'adyen_payments'

export const getAdyenPaymentMethodId = (order: Order) => {
  const adyenPaymentMethod =
    order?.available_payment_methods?.find(isAdyenPaymentMethod)

  return adyenPaymentMethod?.id
}
