<script setup lang="ts">
import '@adyen/adyen-web/dist/adyen.css'
import type { Order } from '@commercelayer/sdk'
import {
  getShippingMethods,
  isPickUpInStore,
  isShippingMethodFree,
} from '@design-system/utils/shippingMethods'
import {
  getAdyenPaymentMethodId,
  isAdyenPaymentMethod,
} from '@integration-layer/utils/paymentMethods'

const { ts } = useI18n()
const { language, thankYouUrl } = useRouteHelper()

const {
  cart,
  setPaymentMethod,
  setAdyenPaymentSource,
  updateAdyenPaymentSource,
  place,
  setShippingMethod,
  setShippingAddress,
  setBillingAddressSameAsShippingAddress,
  setCustomerEmail,
  removeAddresses,
} = useCart()

const { country } = useRouteHelper()

let component: any

const isLoading = useLoaderState()
const { addSnackbar } = useHeaderSnackbars()
const setError = () => {
  isLoading.value = false
  addSnackbar(`adyen-error`, {
    message: {
      key: 'checkout.paymentStep.error',
    },
    duration: 0,
    textClasses: 'text-primitives-red',
  })
}

const getLineItems = (order: Order): ApplePayJS.ApplePayLineItem[] => [
  {
    label: ts('checkoutPage.recapSidebar.subtotal'),
    type: 'final',
    amount: order.subtotal_amount_float!.toFixed(2),
  },
  {
    label: 'Shipping',
    type:
      order.shipments?.length! > 0 && order.shipments?.[0].shipping_method
        ? 'final'
        : 'pending',
    amount: order.shipping_amount_float!.toFixed(2),
  },
  ...(order.tax_included
    ? []
    : [
        {
          label: ts('cartPage.recap.tax'),
          type: order.shipping_address ? 'final' : 'pending',
          amount: order.total_tax_amount_float!.toFixed(2),
        } as ApplePayJS.ApplePayLineItem,
      ]),
  ...(order.discount_amount_float
    ? []
    : [
        {
          label: ts('cartPage.recap.promoCodeLabel'),
          type: 'final' as const,
          amount: order.discount_amount_float!.toFixed(2),
        },
      ]),
]

const getTotal = (order: Order): ApplePayJS.ApplePayLineItem => ({
  label: 'Armani',
  type: 'final',
  amount: order.total_amount_with_taxes_float!.toFixed(2),
})

const getFormattedShippingMethods = (
  order: Order
): ApplePayJS.ApplePayShippingMethod[] =>
  getShippingMethods(order)
    ?.filter(method => !isPickUpInStore(method))
    ?.map(shippingMethod => ({
      label: shippingMethod.name,
      detail:
        shippingMethod?.delivery_lead_time_for_shipment?.min_days &&
        shippingMethod.delivery_lead_time_for_shipment.max_days
          ? ts('cartPage.recap.sideSheet.shipping.deliveryIn', {
              min: shippingMethod.delivery_lead_time_for_shipment.min_days,
              max: shippingMethod.delivery_lead_time_for_shipment.max_days,
            })
          : '',
      amount: isShippingMethodFree(order, shippingMethod)
        ? '0.00'
        : shippingMethod.price_amount_float!.toFixed(2),
      identifier: shippingMethod.id,
    })) ?? []

const setupPayment = async () => {
  if (!cart.value) return

  const paymentMethod = cart.value.payment_method

  if (!paymentMethod || !isAdyenPaymentMethod(paymentMethod)) {
    const adyenPaymentMethodId = getAdyenPaymentMethodId(cart.value)
    if (!adyenPaymentMethodId) throw new Error('adyenPaymentMethodId not found')

    await setPaymentMethod(adyenPaymentMethodId)
    await setAdyenPaymentSource()
  }
  const paymentMethods =
    // @ts-ignore
    cart.value.payment_source?.payment_methods?.paymentMethods
  if (
    !paymentMethods.some(({ type }: { type: string }) => type === 'applepay')
  ) {
    return
  }

  const { default: AdyenCheckout } = await import('@adyen/adyen-web')
  // @ts-ignore
  const checkout = await AdyenCheckout({
    // @ts-expect-error
    environment: cart.value?.payment_source?.public_key?.startsWith('test')
      ? 'test'
      : 'live',
    // @ts-expect-error
    clientKey: cart.value?.payment_source?.public_key,
    locale: language,
    amount: {
      currency: cart.value?.currency_code || '',
      value: cart.value?.total_amount_with_taxes_cents || 0,
    },
    countryCode: country.toUpperCase(),
    paymentMethodsResponse: {
      paymentMethods,
    },
    showPayButton: true,
    onError: handleError,
    onChange: handleOnChange,
    onAdditionalDetails: handleOnAdditionalDetails,
    onSubmit: onSubmit,
  })

  component = checkout.create('applepay', {
    buttonType: 'buy',
    buttonColor: 'white-with-line',
    countryCode: country.toUpperCase(), // Required field.
    //Required for v6.0.0 or later.
    isExpress: true,

    // Step 2: Configure Apple Pay to return the shopper's details.
    // requiredBillingContactFields: ['postalAddress'],
    requiredShippingContactFields: ['postalAddress', 'name', 'phone', 'email'],

    // Step 3: Configure the callback to handle shipping address changes.
    onShippingContactSelected: async (resolve, reject, event) => {
      try {
        console.log(event)
        const shippingContact = event.shippingContact

        if (
          shippingContact.countryCode?.toUpperCase() !== country.toUpperCase()
        ) {
          const update = {
            newTotal: getTotal(cart.value!),
            errors: [
              new ApplePayError(
                'shippingContactInvalid',
                'countryCode',
                // TODO: (tracked #1190) localized labels
                'Cannot ship to the selected address'
              ),
            ],
          }
          console.log(update)
          resolve(update)
          return
        }

        await setShippingAddress({
          first_name: shippingContact.givenName || 'placeholder',
          last_name: shippingContact.familyName || 'placeholder',
          line_1: shippingContact.addressLines?.[0] || 'placeholder',
          line_2: shippingContact.addressLines?.[1] || 'placeholder',
          city: shippingContact.locality!,
          zip_code: shippingContact.postalCode,
          state_code: shippingContact.administrativeArea!,
          country_code: shippingContact.countryCode!,
          phone: shippingContact.phoneNumber || 'placeholder',
          email: shippingContact.emailAddress || 'placeholder',
        })
        await setBillingAddressSameAsShippingAddress()

        if (!cart.value?.shipments?.[0]?.available_shipping_methods?.length) {
          const update = {
            newTotal: getTotal(cart.value!),
            errors: [new ApplePayError('shippingContactInvalid')],
          }
          console.log(update)
          resolve(update)
          return
        }

        const newShippingMethods = getFormattedShippingMethods(cart.value!)

        await setShippingMethod(newShippingMethods![0].identifier)

        const update = {
          newTotal: getTotal(cart.value!),
          newLineItems: getLineItems(cart.value!),
          newShippingMethods,
        }

        console.log(update)
        resolve(update)
      } catch (err) {
        // @ts-expect-error
        console.error(err?.errors ?? err)
        reject(err)
      }
    },

    // Step 4: Configure the callback to handle shipping method changes.
    onShippingMethodSelected: async (resolve, reject, event) => {
      try {
        const { shippingMethod } = event
        console.log(event)

        await setShippingMethod(shippingMethod.identifier)

        const update = {
          newTotal: getTotal(cart.value!),
          newLineItems: getLineItems(cart.value!),
        }

        resolve(update)
      } catch (err) {
        // @ts-expect-error
        console.error(err?.errors ?? err)
        reject(err)
      }
    },

    // Step 5: Configure the callback to get the shopper's information.
    onAuthorized: async (resolve, reject, { payment }) => {
      console.log('onAuthorized', payment)
      try {
        const shippingContact = payment!.shippingContact!
        await setCustomerEmail(shippingContact.emailAddress!)
        const completeAddress = {
          first_name: shippingContact!.givenName!,
          last_name: shippingContact!.familyName!,
          line_1: shippingContact!.addressLines![0]!,
          line_2: shippingContact!.addressLines![1],
          city: shippingContact!.locality!,
          zip_code: shippingContact!.postalCode!,
          state_code: shippingContact!.administrativeArea!,
          country_code: shippingContact!.countryCode!,
          phone: shippingContact!.phoneNumber!,
          email: shippingContact!.emailAddress!,
        }
        await setShippingAddress(completeAddress)
        await setBillingAddressSameAsShippingAddress()
        await setShippingMethod(cart.value!.metadata!.shippingMethodId)
        await handlePayment(onSubmitState, onSubmitComponent)
        authorizeDeferredPromise?.resolve()
        resolve()
      } catch (err) {
        authorizeDeferredPromise?.reject()
        reject()
      }
    },
  })

  // Step 6: Mount the Component.
  try {
    await component.isAvailable()
    component.mount('#applepay-container')
  } catch (e) {
    console.log(e)
  }
}

function cleanUrlBy(symbol: string = '?'): string {
  const currentLocation = window?.location.href
  const [splitLocation] = currentLocation.split(symbol)
  const location = splitLocation ?? currentLocation
  const locationWithoutTrilingSlash = location.endsWith('/')
    ? location.slice(0, -1)
    : location
  return locationWithoutTrilingSlash
}

const getPaymentRequestData = (state: any) => ({
  payment_method: state.data.paymentMethod,
  origin: window.location.origin,
  return_url: `${cleanUrlBy()}/adyen`,
  redirect_from_issuer_method: 'GET',
  browser_info: {
    acceptHeader:
      'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
    ...getBrowserInfo(),
  },
})

const handleOnChange = async (state: any, component: any) => {
  console.log('handleOnChange', state)
  if (state.isValid) {
    await updateAdyenPaymentSource({
      payment_request_data: {
        shopperInteraction: 'Ecommerce',
        recurringProcessingModel: 'CardOnFile',
        ...getPaymentRequestData(state),
      },
    })
  }
}

const handleOnAdditionalDetails = async (state: any, component: any) => {
  console.log('handleOnAdditionalDetails', state)
  const paymentSource = await updateAdyenPaymentSource({
    payment_request_details: state.data,
    _details: true,
  })

  handlePaymentResponse(paymentSource.payment_response, component)
}

const handlePayment = async (state: any, component: any) => {
  console.log('handlePayment', state)
  const paymentRequestData = {
    ...state.data,
    ...getPaymentRequestData(state),
  }

  await updateAdyenPaymentSource({
    payment_request_data: paymentRequestData,
  })

  // delete paymentRequestData.paymentMethod
  const paymentSource = await updateAdyenPaymentSource({
    // payment_request_data: paymentRequestData,
    // @ts-expect-error
    _authorize: true,
  })

  await handlePaymentResponse(paymentSource.payment_response, component)
}

const handlePaymentResponse = async (paymentResponse: any, component: any) => {
  if (paymentResponse.action !== undefined) {
    component.handleAction(paymentResponse.action)
  } else {
    switch (paymentResponse.resultCode) {
      case 'Authorised':
      case 'Pending':
      case 'Received': {
        const order = await place()
        await navigateTo(thankYouUrl(order!.id))
        return
      }
      case 'Cancelled':
      case 'Error':
      case 'Refused': {
        setError()
        break
      }
    }
    if (paymentResponse.errorCode) {
      setError()
    }
  }
}

const handleError = () => {
  removeAddresses().catch(() => null)
  setError()
  authorizeDeferredPromise?.reject()
}

let onSubmitState: any
let onSubmitComponent: any
let authorizeDeferredPromise: DeferredPromise | undefined

const onSubmit = async (state: any, component: any) => {
  try {
    isLoading.value = true
    onSubmitState = state
    onSubmitComponent = component
    authorizeDeferredPromise = deferredPromise()
    await authorizeDeferredPromise.promise
  } catch (err) {
    isLoading.value = false
    console.log('onSubmit error', err)
    throw err
  }
}

onMounted(setupPayment)
</script>

<template>
  <div id="applepay-container" class="w-full"></div>
</template>

<style scoped lang="scss">
#applepay-container :deep(.adyen-checkout__applepay__button) {
  width: 100%;
  border-radius: 0;
}
</style>
