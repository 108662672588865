export const useCartDeleteTimeout = () => {
  const { removeFromCart } = useCart()

  const duration = 7000

  const cartTimeOut = useState<{ itemId: string; timeoutFn: NodeJS.Timeout }[]>(
    `cart-timeout`,
    () => []
  )

  const removeFromCartTimeout = (itemId: string, cb?: () => void) => {
    const alreadyRemoving = cartTimeOut.value.find(
      item => item.itemId === itemId
    )
    if (alreadyRemoving) return

    cartTimeOut.value.push({
      itemId,
      timeoutFn: setTimeout(async () => {
        const isActive = cartTimeOut.value.some(item => item.itemId === itemId)
        if (isActive) {
          await removeFromCart(itemId)
          undoRemoveID(itemId)
          cb?.()
        }
      }, duration),
    })
  }

  const undoRemoveID = (itemId: string) => {
    const index = cartTimeOut.value.findIndex(item => item.itemId === itemId)
    if (index !== -1) {
      clearTimeout(cartTimeOut.value[index].timeoutFn)
      cartTimeOut.value.splice(index, 1)
    }
  }

  const isAnyTimeoutRunning = computed(() => !!cartTimeOut.value.length)

  return {
    duration,
    cartTimeOut,
    isAnyTimeoutRunning,
    removeFromCartTimeout,
    undoRemoveID,
  }
}
